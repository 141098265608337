/**
 * @description URL.canParse를 지원하지 않는 브라우저를 대응합니다.
 * @see https://daangn.sentry.io/issues/5770548936/?project=4505514179231744&referrer=project-issue-stream
 */
export function canParse(url: string | URL, base?: string) {
  try {
    new URL(url, base);
    return true;
  } catch (e) {
    return false;
  }
}
