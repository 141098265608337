import { useEffect, useState } from "react";

export type AgentData = {
  isSSR: boolean;
  isMobile?: Boolean;
  isKarrotBrowser?: boolean;
};

const useDetectUserAgent = () => {
  const [deviceData, setDeviceData] = useState<AgentData>({
    isSSR: true,
    isMobile: undefined,
    isKarrotBrowser: undefined,
  });

  useEffect(() => {
    const matched = navigator.userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i,
    );
    const karrotBrowser = navigator.userAgent.match(
      /Karrot|KARROT|TowneersApp/gi,
    );
    setDeviceData({
      isSSR: false,
      isMobile: matched !== null && matched !== undefined,
      isKarrotBrowser: karrotBrowser !== null && karrotBrowser !== undefined,
    });
  }, []);

  return deviceData;
};

export default useDetectUserAgent;
