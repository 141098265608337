import type { Values } from "@karrotmarket-com/type-utility";

import type { Services } from "~/core/services";
import { useDisclosure } from "~/hooks/useDisclosure";
import { useKRAppScheme } from "~/services/useKRAppScheme";

import useDetectUserAgent from "./useDetectUserAgent";
import { useTrackClickButtonMoveToApp } from "./useTrackClickButtonMoveToApp";

export const Position = {
  DetailPage: "detail_page",
  GNB: "gnb",
  AllPage: "all_page",
  AppDownloadBanner: "app_download_banner",
  ServicePage: "service_page",
  FAB: "fab",
} as const;

type OpenQrModalParams = {
  position: Values<typeof Position>;
  appScheme: string;
  serviceName?: Services;
  status?: string;
};

export function useMoveToApp() {
  const { isOpen, onOpen, setOpenState } = useDisclosure();
  const userAgent = useDetectUserAgent();
  const { trackClickButtonMoveToApp } = useTrackClickButtonMoveToApp();

  const moveToApp = (params: OpenQrModalParams) => {
    trackClickButtonMoveToApp({
      position: params.position,
      serviceName: params.serviceName,
      status: params.status,
    });

    if (userAgent.isMobile) {
      window.open(params.appScheme);
      return;
    }

    onOpen();
  };

  return {
    isQrModalOpen: isOpen,
    moveToApp,
    setQrModalStatus: setOpenState,
  };
}

export function useMoveToKRApp() {
  const { isOpen, onOpen, setOpenState } = useDisclosure();
  const userAgent = useDetectUserAgent();
  const { getQrLink, openApp } = useKRAppScheme();
  const { trackClickButtonMoveToApp } = useTrackClickButtonMoveToApp();

  const moveToApp = (params: OpenQrModalParams) => {
    trackClickButtonMoveToApp({
      position: params.position,
      serviceName: params.serviceName,
      status: params.status,
    });

    if (userAgent.isMobile) {
      openApp(params.appScheme);
      return;
    }

    onOpen();
  };

  return {
    isQrModalOpen: isOpen,
    moveToApp,
    setQrModalStatus: setOpenState,
    getQrLink,
  };
}
