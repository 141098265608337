import { VisuallyHidden } from "@daangn/carotene";
import {
  IconChevronLeftLine,
  IconChevronRightLine,
} from "@daangn/react-monochrome-icon";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

import * as css from "./HorizontalScroll.css";
const SCROLL_END_THRESHOLD = 8;

export type HorizontalScrollProps = {
  children: React.ReactNode;
  scrollContainerClassName?: string;
  mainContentHeight?: {
    large: string;
    xlarge: string;
    xxlarge: string;
  };
  arrows?: boolean;
  fade?: boolean;
};
const HorizontalScroll = ({ fade = true, ...props }: HorizontalScrollProps) => {
  const [position, setPosition] = useState<"left-end" | "right-end" | "middle">(
    "left-end",
  );

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollContainerRef.current) {
      return;
    }

    const $scrollContainer = scrollContainerRef.current;

    const onScroll = () => {
      if ($scrollContainer.scrollLeft <= SCROLL_END_THRESHOLD) {
        return setPosition("left-end");
      }
      if (
        $scrollContainer.scrollLeft + $scrollContainer.clientWidth >=
        $scrollContainer.scrollWidth - SCROLL_END_THRESHOLD
      ) {
        return setPosition("right-end");
      }

      setPosition("middle");
    };

    $scrollContainer.addEventListener("scroll", onScroll);

    return () => {
      $scrollContainer.removeEventListener("scroll", onScroll);
    };
  }, [scrollContainerRef]);

  return (
    <div className={css.container}>
      <div
        className={clsx(css.scrollContainer, props.scrollContainerClassName)}
        ref={scrollContainerRef}
      >
        {props.children}
      </div>
      <div
        className={css.fade({
          position: "left",
          hidden: position === "left-end" || !fade,
        })}
      />
      <div
        className={css.fade({
          position: "right",
          hidden: position === "right-end" || !fade,
        })}
      />
      {props.arrows && (
        <>
          <div
            className={css.arrowContainer({ position: "left" })}
            style={assignInlineVars({
              [css.localVars.mainContentSize.large]: props.mainContentHeight
                ?.large as string,
              [css.localVars.mainContentSize.xlarge]: props.mainContentHeight
                ?.xlarge as string,
              [css.localVars.mainContentSize.xxlarge]: props.mainContentHeight
                ?.xxlarge as string,
            })}
          >
            <button
              className={css.arrow({
                position: "left",
                hidden: position === "left-end",
              })}
              onClick={() => {
                scrollContainerRef.current?.scroll({
                  behavior: "smooth",
                  left:
                    scrollContainerRef.current.scrollLeft -
                    scrollContainerRef.current.clientWidth,
                });
              }}
            >
              <VisuallyHidden>Previous</VisuallyHidden>
              <IconChevronLeftLine className={css.arrowIcon} />
            </button>
          </div>
          <div
            className={css.arrowContainer({ position: "right" })}
            style={assignInlineVars({
              [css.localVars.mainContentSize.large]: props.mainContentHeight
                ?.large as string,
              [css.localVars.mainContentSize.xlarge]: props.mainContentHeight
                ?.xlarge as string,
              [css.localVars.mainContentSize.xxlarge]: props.mainContentHeight
                ?.xxlarge as string,
            })}
          >
            <button
              className={css.arrow({
                position: "right",
                hidden: position === "right-end",
              })}
              onClick={() => {
                scrollContainerRef.current?.scroll({
                  behavior: "smooth",
                  left:
                    scrollContainerRef.current.scrollLeft +
                    scrollContainerRef.current.clientWidth,
                });
              }}
            >
              <VisuallyHidden>Next</VisuallyHidden>
              <IconChevronRightLine className={css.arrowIcon} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default HorizontalScroll;
