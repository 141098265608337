import { Button, Icon, Text } from "@daangn/carotene";
import {
  IconChevronDownFill,
  IconXmarkLine,
} from "@daangn/react-monochrome-icon";

import { useDisclosure } from "~/hooks/useDisclosure";
import { ExternalLink } from "~/services/ExternalLink";
import type { NavLinkWithSubLinksList } from "~/services/gnb/GNBParse";

import {
  CommonSearchParamKey,
  buildRegionParamValue,
} from "@karrotmarket-com/core";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import KarrotLogo from "~/services/KarrotLogo";
import { Link, useRootLoaderData } from "../remix-lib";
import PageMaxWidthWrapper from "../services/PageMaxWidthWrapper";
import * as hamburgerMenuStyle from "../services/gnb/HamburgerMenuStyle.css";

import { GtmVariableName } from "../utils/GtmVariable";
import { HAMBURGER_TRANSITION_DURATION } from "./GlobalNavigationBar.css";
import * as css from "./GlobalNavigationBar__HamburgerMenu.css";

function fadeAnimationDelay(i: number) {
  return HAMBURGER_TRANSITION_DURATION / 1.5 + 60 * i;
}

type HamburgerMenuSubLinksProps = {
  subLinks: NavLinkWithSubLinksList[0]["SubLinks"];
  moreSubLinkButtonLabel: string;
  animationIndex: number;
};
const HamburgerMenuSubLinks = (props: HamburgerMenuSubLinksProps) => {
  const { isOpen: expanded, onOpen: expand } = useDisclosure();

  return (
    <ul
      className={css.navSubLinkList({
        expanded,
      })}
    >
      {props.subLinks?.map((subLink) => (
        <li className={css.navSubLinkContainer} key={subLink.NavLinkLabel}>
          {subLink.NavLinkExternal ? (
            <ExternalLink
              data-gtm={GtmVariableName.GnbCategory}
              className={css.navSubLink}
              href={subLink.NavLinkLink}
              style={{
                animationDelay: `${
                  fadeAnimationDelay(props.animationIndex) + 15
                }ms`,
              }}
            >
              {subLink.NavLinkLabel}
            </ExternalLink>
          ) : (
            <Link
              data-gtm={GtmVariableName.GnbCategory}
              className={css.navSubLink}
              href={subLink.NavLinkLink}
              style={{
                animationDelay: `${
                  fadeAnimationDelay(props.animationIndex) + 15
                }ms`,
              }}
            >
              {subLink.NavLinkLabel}
            </Link>
          )}
        </li>
      ))}
      {!expanded && (
        <li>
          <button
            type="button"
            className={css.navSubLinkMore}
            style={{
              animationDelay: `${
                fadeAnimationDelay(props.animationIndex) + 15
              }ms`,
            }}
            onClick={expand}
          >
            <div className={css.navSubLinkMoreIconContainer}>
              <Icon
                svg={<IconChevronDownFill aria-hidden />}
                size={3}
                color="neutralMuted"
              />
            </div>
            {props.moreSubLinkButtonLabel}
          </button>
        </li>
      )}
    </ul>
  );
};

export type HamburgerMenuContentProps = {
  navLinks: NavLinkWithSubLinksList;
  appDownloadButtonLabel: string;
  appDownloadCaptionLabel: string;
  moreSubLinkButtonLabel: string;
  onClickAppDownloadButton: () => void;
};

const HamburgerMenuContent = (props: HamburgerMenuContentProps) => {
  const rootData = useRootLoaderData();
  return (
    <Dialog.Portal>
      <Dialog.Overlay className={css.dim} />
      <Dialog.Content
        className={clsx(
          hamburgerMenuStyle.dialogContent.global,
          hamburgerMenuStyle.dialogContentSelector,
          hamburgerMenuStyle.forceWaitExitAnimation,
        )}
      >
        <header className={hamburgerMenuStyle.dialogHeader}>
          <Link
            data-gtm={GtmVariableName.GnbLogo}
            href={`/${rootData.karrotLocalCountryCode}/?${CommonSearchParamKey.In}=${buildRegionParamValue({ region: rootData.region })}`}
            className={hamburgerMenuStyle.logo}
            aria-label="Go to karrotmarket.com home"
          >
            <KarrotLogo location="gnb" />
          </Link>
          <Dialog.Close>
            <IconXmarkLine size={24} aria-label="close" />
          </Dialog.Close>
        </header>

        <PageMaxWidthWrapper
          className={clsx(
            hamburgerMenuStyle.moveUpDownSection,
            hamburgerMenuStyle.container.global,
          )}
        >
          <nav>
            <ul className={css.navList}>
              {props.navLinks.map((linkWithSubLinks, i) => (
                <li
                  key={linkWithSubLinks.Link.NavLinkLabel}
                  className={css.navLinkItem}
                >
                  <Link
                    data-gtm={GtmVariableName.GnbMenu}
                    className={css.navLink}
                    href={linkWithSubLinks.Link.NavLinkLink}
                    style={{
                      animationDelay: `${fadeAnimationDelay(i)}ms`,
                    }}
                  >
                    {linkWithSubLinks.Link.NavLinkLabel}
                  </Link>
                  {linkWithSubLinks.SubLinks && (
                    <HamburgerMenuSubLinks
                      subLinks={linkWithSubLinks.SubLinks}
                      moreSubLinkButtonLabel={props.moreSubLinkButtonLabel}
                      animationIndex={i}
                    />
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div
            className={hamburgerMenuStyle.bottom}
            style={{
              animationDelay: `${fadeAnimationDelay(props.navLinks.length)}ms`,
            }}
          >
            <Text size="medium" color="neutral">
              {props.appDownloadCaptionLabel}
            </Text>
            <div className={hamburgerMenuStyle.bottomButton}>
              <Button
                size="large"
                variant="brandWeak"
                data-gtm={GtmVariableName.GnbDownload}
                onClick={props.onClickAppDownloadButton}
              >
                {props.appDownloadButtonLabel}
              </Button>
            </div>
          </div>
        </PageMaxWidthWrapper>
      </Dialog.Content>
    </Dialog.Portal>
  );
};

const HamburgerMenuRoot = Dialog.Root;
HamburgerMenuRoot.displayName = "HamburgerMenuRoot";
const HamburgerMenuTrigger = Dialog.Trigger;
HamburgerMenuTrigger.displayName = "HamburgerMenuTrigger";

const HamburgerMenu = {
  Root: HamburgerMenuRoot,
  Trigger: HamburgerMenuTrigger,
  Content: HamburgerMenuContent,
};

export default HamburgerMenu;
