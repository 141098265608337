/**
 * @description 코드 내에서 서비스를 식별하는 코드에요.
 * 대표적으로 URL(site-urls.ts)에 사용해요.
 */
export const enum Services {
  /**
   * 중고거래
   */
  BUY_SELL = "buy-sell",
  /**
   * 알바
   */
  JOBS = "jobs",
  /**
   * 중고차
   */
  CARS = "cars",
  /**
   * 부동산
   */
  REALTY = "realty",
  /**
   * 로컬 프로필
   */
  LOCAL_PROFILE = "local-profile",
  /**
   * 비즈니스 소식
   */
  BUSINESS_POST = "business-post",
  /**
   * 동네생활
   */
  COMMUNITY = "community",
  /**
   * 모임
   */
  GROUP = "group",
}
