import { cloneDeep, omit, unset } from "lodash";
import { pipe } from "lodash/fp";
import qs from "query-string";
import { z } from "zod";

import { CommonSearchParamKey, buildRegionParamValue } from "~/core";
import { changeKeys } from "~/utils/changeKeys";

import type { BuySellAllPageFilters } from "./BuySellAllPageFilters";
import { BuySellAllPageFilterKey } from "./BuySellAllPageFilters";

const buySellAllPageQueryVariablesSchema = z.object({
  region_id: z.string(),
  category_id: z.string().optional(),
  price: z.string().optional(),
  search: z.string().min(1).optional(),
  only_on_sale: z.boolean().optional(),
});

export namespace BuySellSearchParams {
  export const queryStringToVariables = (
    queryString: string,
    regionId: string,
  ) => {
    return parseQueryString(queryString, regionId);
  };

  const parseQueryString = (queryString: string, regionId: string) => {
    const queryObject = qs.parse(queryString, { parseBooleans: true });
    queryObject.region_id = regionId;

    const result = buySellAllPageQueryVariablesSchema.safeParse(queryObject);
    if (result.success) {
      return result.data;
    }

    const errorFields = Object.keys(result.error.format());
    return buySellAllPageQueryVariablesSchema.parse(
      omit(queryObject, errorFields),
    );
  };

  export const stringify = (
    camelCaseVariables: BuySellAllPageFilters,
    region: {
      id: string;
      name?: string | undefined;
    },
  ) => {
    const regionQueryString = [
      CommonSearchParamKey.In,
      buildRegionParamValue({ region }),
    ].join("=");

    const clonedVariables = cloneDeep(camelCaseVariables);
    mutateVariables(clonedVariables);

    const snakeCaseVariables = changeKeys.snakeCase(clonedVariables);

    const searchParams = pipe(
      (variables) =>
        qs.stringify(variables, {
          skipNull: true,
          skipEmptyString: true,
        }),
      (stringifiedVariables) => new URLSearchParams(stringifiedVariables),
    )(snakeCaseVariables);
    searchParams.sort();

    return `?${[regionQueryString, searchParams.toString()]
      .filter(Boolean)
      .join("&")}`;
  };

  const mutateVariables = (variables: BuySellAllPageFilters) => {
    unset(variables, BuySellAllPageFilterKey.RegionId);
    if (variables.onlyOnSale === false) {
      unset(variables, BuySellAllPageFilterKey.OnlyOnSale);
    }
  };
}
