export enum GtmVariableName {
  GnbLogo = "gnb_logo",
  GnbHamburger = "gnb_hamburger_menu",
  GnbMenu = "gnb_menu",
  GnbCategory = "gnb_category",
  GnbLocation = "gnb_location",
  GnbSearch = "gnb_search",
  GnbDownload = "gnb_app_download",
  GnbPopularKeyword = "gnb_popular_keyword",
  GnbRelatedKeyword = "gnb_related_keyword",
  FooterAppDownload = "footer_app_download",
  FooterSocial = "footer_social",
  FooterNavigation = "footer_navigation",
  MainPageSeeAll = "main_see_all",
  MainPageArticle = "main_article",
  MainPagePopularCategory = "main_popular_category",
  AllPageSearchFilter = "search_filter",
  AllPageShowMoreOptions = "search_show_more_options",
  AllPageArticle = "search_article",
  AllPageShowMoreArticles = "search_show_more_articles",
  CategoriesPage = "categories",
  BuySellPageProfileRegion = "buy_sell_profile_region",
  BuySellPageProfileNickname = "buy_sell_profile_nickname",
  BuySellPageProfileImage = "buy_sell_profile_image",
  BuySellPageCarousel = "buy_sell_detail_photo",
  BuySellPageDownload = "buy_sell_detail_download",
  BuySellPageUserArticleSeeAll = "buy_sell_detail_user_article_see_all",
  BuySellPageUserArticle = "buy_sell_detail_user_article",
  BuySellPageSeeAll = "buy_sell_detail_recommended_see_all",
  BuySellPageRecommendedArticle = "buy_sell_detail_recommended_article",
  UserPageArticle = "user_article",
  UserPageProfileRegion = "user_profile_region",
  UserPageProfileNickname = "user_profile_nickname",
  UserPageProfileImage = "user_profile_image",
  JobPageClickWriteFAB = "click_write_fab",
}

export type GtmVariable = keyof typeof GtmVariableName;
