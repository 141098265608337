/**
 * TODO: 중고거래 필터 관련 로직 `/services/buy-sell/` 하위로 위치해야함
 */
export enum AllPageSearchParamKey {
  CategoryId = "category_id",
  SortBy = "sort_by",
  Price = "price",
  Search = "search",
}

export type AllPageSearchParams = keyof typeof AllPageSearchParamKey;
