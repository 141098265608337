import {
  CommonSearchParamKey,
  buildRegionParamValue,
} from "@karrotmarket-com/core";
import type {
  GetKeywordsResult,
  KeywordRecordV1,
  KeywordRecordV2,
} from "@karrotmarket-com/strategy-keywords";
import type { MemberOf, Values } from "@karrotmarket-com/type-utility";
import { z } from "zod";

import { AllPageSearchParamKey } from "~/core";

type MakeKeywordLinksParams = {
  pathname: string;
  region: {
    id: string;
    name?: string | undefined;
  };
  keywordRecord: GetKeywordsResult;
};

export const makeKeywordLinks = ({
  pathname,
  region,
  keywordRecord,
}: MakeKeywordLinksParams) => {
  const keywordLinks = keywordRecord.values.flatMap((value) => {
    const search = new URLSearchParams({
      [CommonSearchParamKey.In]: buildRegionParamValue({
        region,
      }),
    });

    buildSearchParam({
      searchParams: search,
      schemaVersion: keywordRecord.schemaVersion,
      value,
    });

    return {
      value: getKeywordDisplayName(value),
      href: `${pathname}?${search.toString()}`,
    };
  });

  return keywordLinks;
};

/**
 * Gets the display name of a keyword value.
 */
function getKeywordDisplayName(value: MemberOf<GetKeywordsResult["values"]>) {
  return typeof value === "string" ? value : value.keyword;
}

function buildSearchParam({
  searchParams,
  schemaVersion,
  value,
}: {
  searchParams: URLSearchParams;
  schemaVersion: string;
  value: MemberOf<Values<KeywordRecordV1 | KeywordRecordV2>>;
}) {
  if (typeof value === "string" && schemaVersion === "v1") {
    return buildKeywordSearchParamV1({
      searchParams,
      value,
    });
  }

  if (isKeywordRecordV2(value) && schemaVersion === "v2") {
    return buildKeywordSearchParamV2({
      searchParams,
      value,
    });
  }

  throw new Error("buildSearchParam: Invalid value");
}

type BuildKeywordSearchParamV1Params = {
  searchParams: URLSearchParams;
  value: MemberOf<Values<KeywordRecordV1>>;
};

function buildKeywordSearchParamV1({
  searchParams,
  value,
}: BuildKeywordSearchParamV1Params) {
  searchParams.set(AllPageSearchParamKey.Search, value);

  return searchParams;
}

const keywordRecordV2ValueMemberSchema = z.object({
  keyword: z.string(),
  queryString: z.array(
    z.object({
      key: z.string(),
      value: z.string(),
    }),
  ),
});
function isKeywordRecordV2(
  value: unknown,
): value is MemberOf<Values<KeywordRecordV2>> {
  return keywordRecordV2ValueMemberSchema.safeParse(value).success;
}

type BuildKeywordSearchParamV2Params = {
  searchParams: URLSearchParams;
  value: MemberOf<Values<KeywordRecordV2>>;
};

function buildKeywordSearchParamV2({
  searchParams,
  value,
}: BuildKeywordSearchParamV2Params) {
  for (const qs of value.queryString) {
    searchParams.set(qs.key, qs.value);
  }

  return searchParams;
}
