import { Link, useLocale } from "~/remix-lib";
import { GtmVariableName } from "~/utils/GtmVariable";
import HorizontalScroll from "../HorizontalScroll";
import * as css from "./HorizontalKeywords.css";

type HorizontalKeywordsProps = {
  keywordType: "popular" | "related";
  keywordComponentType?: "chip" | "text";
  keywords: { value: string; href: string }[];
};

export function HorizontalKeywords({
  keywordType,
  keywordComponentType = "chip",
  keywords,
}: HorizontalKeywordsProps) {
  const { t } = useLocale();

  const isPopularKeywords = keywordType === "popular";
  const keywordsTitle = isPopularKeywords
    ? t("GlobalNavigationBar.search_keywords_title_popular")
    : t("GlobalNavigationBar.search_keywords_title_related");
  const keywordsGTM = isPopularKeywords
    ? GtmVariableName.GnbPopularKeyword
    : GtmVariableName.GnbRelatedKeyword;

  return (
    <div className={css.keywordScrollableContainer}>
      <HorizontalScroll>
        <div
          className={css.keywordScrollable({
            type: keywordComponentType,
          })}
        >
          <div
            className={css.keywordTitle({
              type: keywordComponentType,
            })}
          >
            {keywordsTitle}
          </div>
          <ul className={css.keywordList}>
            {keywords.map(({ value, href }) => (
              <li key={value} className={css.keywordContainer}>
                <Link
                  data-gtm={keywordsGTM}
                  className={css.keyword({
                    type: keywordComponentType,
                  })}
                  href={href}
                >
                  {value}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </HorizontalScroll>
    </div>
  );
}
