import { Services } from "./core/services";

export const serviceNameMap = {
  [Services.BUY_SELL]: "BUY_SELL",
  [Services.REALTY]: "REALTY",
  [Services.JOBS]: "JOBS",
  [Services.CARS]: "CARS",
  [Services.COMMUNITY]: "COMMUNITY",
  [Services.GROUP]: "GROUP",
  [Services.LOCAL_PROFILE]: "LOCAL_PROFILE",
  [Services.BUSINESS_POST]: "BUSINESS_POST",
} as const;

/**
 * @description
 * URL의 SSOT는 ServicesKey에요.
 */
export const krServiceHomeUrl = {
  BUY_SELL: `/kr/${Services.BUY_SELL}/`,
  REALTY: `/kr/${Services.REALTY}/`,
  JOBS: `/kr/${Services.JOBS}/`,
  CARS: `/kr/${Services.CARS}/`,
  COMMUNITY: `/kr/${Services.COMMUNITY}/`,
  GROUP: `/kr/${Services.GROUP}/`,
  LOCAL_PROFILE: `/kr/${Services.LOCAL_PROFILE}/`,
  BUSINESS_POST: `/kr/${Services.BUSINESS_POST}/`,
};

export const globalServiceHomeUrl = {
  BUY_SELL: `/${Services.BUY_SELL}/all/`,
};
