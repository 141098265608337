import { Icon } from "@daangn/carotene";
import { atoms } from "@daangn/carotene/css";
import {
  IconArrowUpRightLine,
  IconChevronDownFill,
} from "@daangn/react-monochrome-icon";
import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import clsx from "clsx";

import { Link } from "~/remix-lib";
import { GtmVariableName } from "~/utils/GtmVariable";

import type { NavLinkWithSubLinksList } from "../GNBParse";
import * as css from "./NavigationMenu.css";

interface Props {
  linkWithSubLinks: NavLinkWithSubLinksList;
  className?: string;
}

export function NavigationMenu({ linkWithSubLinks, className }: Props) {
  return (
    <NavigationMenuPrimitive.Root
      delayDuration={100}
      className={clsx(css.navigationRoot, className)}
      aria-label="메인 메뉴"
    >
      <NavigationMenuPrimitive.List className={css.navigationList}>
        {linkWithSubLinks.map((aLinkWithSubLinks) => (
          <NavigationMenuPrimitive.Item
            key={aLinkWithSubLinks.Link.NavLinkLabel}
          >
            {Array.isArray(aLinkWithSubLinks.SubLinks) &&
            aLinkWithSubLinks.SubLinks.length > 0 ? (
              <div className={css.navigationItem}>
                <NavigationMenuPrimitive.Trigger
                  className={css.navigationTrigger}
                >
                  {aLinkWithSubLinks.Link.NavLinkExternal ? (
                    <a
                      data-gtm={GtmVariableName.GnbMenu}
                      className={css.navLinkWithSubLinks}
                      href={aLinkWithSubLinks.Link.NavLinkLink}
                      // biome-ignore lint/a11y/noBlankTarget: 당근 타팀 서비스 링크에는 noreferrer noopener 를 적용하지 않아요
                      target="_blank"
                    >
                      {aLinkWithSubLinks.Link.NavLinkLabel}
                    </a>
                  ) : (
                    <Link
                      data-gtm={GtmVariableName.GnbMenu}
                      className={css.navLinkWithSubLinks}
                      href={aLinkWithSubLinks.Link.NavLinkLink}
                    >
                      {aLinkWithSubLinks.Link.NavLinkLabel}
                    </Link>
                  )}
                  <Icon
                    svg={
                      <IconChevronDownFill
                        aria-hidden
                        className={css.iconExpandMore}
                      />
                    }
                    size={3}
                    color="neutralSubtle"
                  />
                </NavigationMenuPrimitive.Trigger>
                <NavigationMenuPrimitive.Content
                  className={css.navigationSubLinkContent}
                >
                  <ul className={css.navigationSubLinkList}>
                    {aLinkWithSubLinks.SubLinks.map((subLink) => (
                      <li
                        key={subLink.NavLinkLabel}
                        className={css.navigationSubLinkItem}
                      >
                        {subLink.NavLinkExternal ? (
                          <a
                            href={subLink.NavLinkLink}
                            className={css.externalSubLink}
                            // biome-ignore lint/a11y/noBlankTarget: 당근 타팀 서비스 링크에는 noreferrer noopener 를 적용하지 않아요
                            target="_blank"
                          >
                            <span>{subLink.NavLinkLabel}</span>
                            &nbsp;
                            <IconArrowUpRightLine
                              size={14}
                              className={atoms({ color: "neutralSubtle" })}
                            />
                          </a>
                        ) : (
                          <Link href={subLink.NavLinkLink}>
                            {subLink.NavLinkLabel}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </NavigationMenuPrimitive.Content>
              </div>
            ) : aLinkWithSubLinks.Link.NavLinkExternal ? (
              <a
                data-gtm={GtmVariableName.GnbMenu}
                href={aLinkWithSubLinks.Link.NavLinkLink}
                className={css.navLink}
                // biome-ignore lint/a11y/noBlankTarget: 당근 타팀 서비스 링크에는 noreferrer noopener 를 적용하지 않아요
                target="_blank"
              >
                {aLinkWithSubLinks.Link.NavLinkLabel}
              </a>
            ) : (
              <Link
                data-gtm={GtmVariableName.GnbMenu}
                href={aLinkWithSubLinks.Link.NavLinkLink}
                className={css.navLink}
              >
                {aLinkWithSubLinks.Link.NavLinkLabel}
              </Link>
            )}
          </NavigationMenuPrimitive.Item>
        ))}
      </NavigationMenuPrimitive.List>
    </NavigationMenuPrimitive.Root>
  );
}
