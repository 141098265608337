import { useEffect, useState } from "react";
import { useHydrated } from "~/hooks/useHydrated";

export function useGNBBorder() {
  const hydrated = useHydrated();
  const [showBorder, setShowBorder] = useState(false);

  useEffect(() => {
    setShowBorder(hydrated && window.scrollY > 0);
  }, [hydrated]);

  useEffect(() => {
    const onScroll = () => {
      setShowBorder(window.scrollY > 0);
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [setShowBorder]);

  return showBorder;
}
