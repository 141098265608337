export type BuySellAllPageFilters = {
  regionId: string;
  categoryId?: string;
  sortBy?: string;
  price?: string;
  search?: string;
  onlyOnSale?: boolean;
};

export enum BuySellAllPageFilterKey {
  RegionId = "regionId",
  CategoryId = "categoryId",
  SortBy = "sortBy",
  Price = "price",
  Search = "search",
  OnlyOnSale = "onlyOnSale",
}
