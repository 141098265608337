import type {
  Maybe,
  NavLinkWithSubLinksType,
} from "__generated__/sanity/sanitySchema";
import { z } from "zod";

const navLinkSchema = z.object({
  NavLinkLabel: z.string(),
  NavLinkLink: z.string(),
  NavLinkExternal: z.boolean(),
  SuffixArrowIcon: z.boolean(),
});

const navLinkWithSubLinksListSchema = z.array(
  z.object({
    Link: navLinkSchema,
    SubLinks: z.array(navLinkSchema).nullable(),
  }),
);

export type NavLinkWithSubLinksList = z.infer<
  typeof navLinkWithSubLinksListSchema
>;

export function parseNavLinkWithSubLinksList(
  navLinkWithSubLinks?: Maybe<Maybe<NavLinkWithSubLinksType>[]>,
) {
  const parseResult =
    navLinkWithSubLinksListSchema.safeParse(navLinkWithSubLinks);
  return parseResult.success ? parseResult.data : [];
}
