import * as PopoverPrimitive from "@radix-ui/react-popover";
import clsx from "clsx";
import { forwardRef, useEffect } from "react";

import { useDisclosure } from "~/hooks/useDisclosure";

import PageMaxWidthWrapper from "../PageMaxWidthWrapper";
import * as css from "./SearchPopover.css";

export const SearchPopover = (
  props: Omit<
    PopoverPrimitive.PopoverProps,
    "open" | "defaultOpen" | "onOpenChange"
  >,
) => {
  const { isOpen, setOpenState } = useDisclosure();

  useEffect(() => {
    const onScroll = () => setOpenState(false);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <PopoverPrimitive.Root
      {...props}
      open={isOpen}
      onOpenChange={setOpenState}
    />
  );
};
export const SearchPopoverTrigger = PopoverPrimitive.Trigger;
export const SearchPopoverAnchor = PopoverPrimitive.Anchor;

export const SearchPopoverContent = forwardRef<
  HTMLDivElement,
  PopoverPrimitive.PopoverContentProps
>(({ children, className, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      className={clsx(css.popoverContent, className)}
      {...props}
      ref={ref}
    >
      <PageMaxWidthWrapper>{children}</PageMaxWidthWrapper>
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
));
