import type { AnchorHTMLAttributes, ReactNode } from "react";

interface Props
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "rel" | "target"> {
  children: ReactNode;
}

export function ExternalLink({ children, ...props }: Props) {
  return (
    <a {...props} rel="noreferrer noopener" target="_blank">
      {children}
    </a>
  );
}
