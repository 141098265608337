import { cond, constant, stubTrue } from "lodash";
import UrlPattern from "url-pattern";

import { Services } from "~/core/services";

const urlPatternOptions = {
  segmentValueCharset: "a-zA-Z0-9가-힣-_%",
};

const match = cond([
  [
    (pathname: string) =>
      new UrlPattern(
        `(/:c)/${Services.BUY_SELL}/(:id)(/)`,
        urlPatternOptions,
      ).match(pathname) !== null,
    constant(Services.BUY_SELL),
  ],
  [
    (pathname: string) =>
      new UrlPattern(
        `(/:c)/${Services.CARS}/(:id)(/)`,
        urlPatternOptions,
      ).match(pathname) !== null,
    constant(Services.CARS),
  ],
  [
    (pathname: string) =>
      new UrlPattern(
        `(/:c)/${Services.JOBS}/(:id)(/)`,
        urlPatternOptions,
      ).match(pathname) !== null,
    constant(Services.JOBS),
  ],
  [
    (pathname: string) =>
      new UrlPattern(
        `(/:c)/${Services.REALTY}/(:id)(/)`,
        urlPatternOptions,
      ).match(pathname) !== null,
    constant(Services.REALTY),
  ],
  [
    (pathname: string) =>
      new UrlPattern(
        `(/:c)/${Services.LOCAL_PROFILE}/(:id)(/)`,
        urlPatternOptions,
      ).match(pathname) !== null,
    constant(Services.LOCAL_PROFILE),
  ],
  [
    (pathname: string) =>
      new UrlPattern(
        `(/:c)/${Services.BUSINESS_POST}/:id(/)`,
        urlPatternOptions,
      ).match(pathname) !== null,
    constant(Services.LOCAL_PROFILE),
  ],
  [
    (pathname: string) =>
      new UrlPattern(
        `(/:c)/${Services.COMMUNITY}/(:id)(/)`,
        urlPatternOptions,
      ).match(pathname) !== null,
    constant(Services.COMMUNITY),
  ],
  [
    (pathname: string) =>
      new UrlPattern(
        `(/:c)/${Services.GROUP}/(:id)(/)`,
        urlPatternOptions,
      ).match(pathname) !== null,
    constant(Services.GROUP),
  ],
  [stubTrue, constant(null)],
]);

export function getService(pathname: string): Services | null {
  return match(pathname);
}
