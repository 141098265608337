import React from "react";

import SvgFooterLogoEnglish from "~/assets/FooterLogoEnglish";
import SvgFooterLogoKorean from "~/assets/FooterLogoKorean";
import SvgGnbLogoEnglish from "~/assets/GnbLogoEnglish";
import SvgGnbLogoKorean from "~/assets/GnbLogoKorean";
import { useRootLoaderData } from "~/remix-lib";

type KarrotLogoProps = {
  location: "gnb" | "footer";
};
const KarrotLogo: React.FC<KarrotLogoProps> = (props) => {
  const rootData = useRootLoaderData();

  switch (props.location) {
    case "gnb": {
      switch (rootData?.karrotLang) {
        case "ko-KR": {
          return <SvgGnbLogoKorean />;
        }
        default: {
          return <SvgGnbLogoEnglish />;
        }
      }
    }
    case "footer": {
      switch (rootData?.karrotLang) {
        case "ko-KR": {
          return <SvgFooterLogoKorean />;
        }
        default: {
          return <SvgFooterLogoEnglish />;
        }
      }
    }
  }
};

export default KarrotLogo;
