import type { SVGProps } from "react";
const SvgFooterLogoKorean = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={52}
    fill="none"
    viewBox="0 0 203 100"
    {...props}
  >
    <path
      fill="#FF6F0F"
      d="M29.235 36.896C13.09 36.896 0 49.696 0 65.856 0 88.184 29.319 100.032 29.235 100c-.08.032 29.235-11.816 29.235-34.144 0-16.148-13.09-28.96-29.235-28.96m0 40.684a11.07 11.07 0 0 1-10.848-13.238 11.072 11.072 0 0 1 15.097-8.061 11.07 11.07 0 0 1 6.829 10.231A11.07 11.07 0 0 1 29.235 77.6z"
    />
    <path
      fill="#00A05B"
      d="M35.818 0c-6.823 0-11.574 4.768-12.322 10.4-9.095-2.512-16.221 4.4-16.221 12 0 5.82 3.999 10.52 9.33 12.048 4.3 1.228 12.042.312 12.042.312-.04-1.88 1.692-3.944 4.363-5.824 7.599-5.344 13.542-7.864 14.458-15.152C48.428 6.16 42.768 0 35.818 0"
    />
    <path
      fill="#FF6F0F"
      d="M116.495 46.964c-6.175 1.94-16.865 2.972-26.907 2.972V37.72h20.74v-9.096H78.466V59.6c17.425 0 32.638-2.1 39.061-4.088zM131.137 25h-11.106v35.612h11.106V49.448h8.958v-9.716h-8.958zm-20.629 35.528c-11.765 0-20.396 6.484-20.396 16s8.639 16 20.396 16 20.397-6.488 20.397-16-8.631-16-20.397-16m0 23.092c-5.303 0-9.282-2.544-9.282-7.108s3.979-7.104 9.282-7.104c5.304 0 9.283 2.544 9.283 7.104s-3.975 7.108-9.283 7.108m51.215-18.368h-11.354v24.092h45.128v-9.536h-33.774zm32.367-37.28h-44.233v9.536h33.083c0 2.368.112 8-.972 14.4h-40.569v9.864h61.589v-9.848h-10.974c1.472-8.088 1.892-14.392 2.076-23.952"
    />
  </svg>
);
export default SvgFooterLogoKorean;
