import { useEffect, useState } from "react";

import { useHydrated } from "~/hooks/useHydrated";

const SCROLL_Y_THRESHOLD = 104;

export function useSearchButton(initShowSearchButton: boolean = false) {
  const hydrated = useHydrated();
  const [showSearchButton, setShowSearchButton] =
    useState(initShowSearchButton);

  useEffect(() => {
    setShowSearchButton(hydrated && window.scrollY > SCROLL_Y_THRESHOLD);
  }, [hydrated]);

  useEffect(() => {
    let previousScrollY = window.scrollY;

    const onScroll = () => {
      const currentScrollY = window.scrollY;
      if (previousScrollY === currentScrollY) {
        return;
      }

      const direction = previousScrollY < currentScrollY ? "down" : "up";
      previousScrollY = currentScrollY;

      if (direction === "down" && currentScrollY > SCROLL_Y_THRESHOLD) {
        setShowSearchButton(true);
        return;
      }

      if (direction === "up" && currentScrollY < SCROLL_Y_THRESHOLD) {
        setShowSearchButton(false);
        return;
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return showSearchButton;
}
