import {
  camelCase as _camelCase,
  mapKeys,
  snakeCase as _snakeCase,
} from "lodash";

export const changeKeys = {
  snakeCase: <T extends object>(object: T) =>
    mapKeys(object, (_, key) => _snakeCase(key)) as {
      [key in keyof T as key extends string ? SnakeCase<key> : key]: T[key];
    },

  camelCase: <T extends object>(object: T) =>
    mapKeys(object, (_, key) => _camelCase(key)) as {
      [key in keyof T as key extends string ? CamelCase<key> : key]: T[key];
    },
};
type CamelCase<T extends string> = T extends `${infer A}_${infer B}`
  ? `${A}${CamelCase<Capitalize<B>>}`
  : T;

type SnakeCase<
  T extends string,
  R extends string = "",
> = T extends `${infer F}${infer L}`
  ? F extends Uppercase<F>
    ? SnakeCase<L, `${R}_${Lowercase<F>}`>
    : SnakeCase<L, `${R}${F}`>
  : R;
