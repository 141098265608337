import { useCallback, useState } from "react";
export const useDisclosure = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const setOpenState = useCallback((flag: boolean) => {
    setIsOpen(flag);
  }, []);

  return {
    isOpen,
    onClose,
    onOpen,
    onToggle,
    setOpenState,
  };
};
