import type { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import { isNil } from "lodash";

import type { Services } from "~/core/services";

declare global {
  interface Window {
    dataLayer: {
      push: (args: { event: string; data: Record<string, unknown> }) => void;
    };
  }
}

type TrackEventParams = {
  eventName: string;
  data: Record<string, unknown>;
};
function trackEvent({ eventName, data }: TrackEventParams) {
  if (isNil(window) || isNil(window.dataLayer)) {
    console.log({
      eventName,
      data,
    });
    return;
  }

  window.dataLayer.push({
    event: eventName,
    data,
  });
}

type TrackSubmitSearchKeywordParams = {
  keyword: string;
  serviceName: Services;
  countryCode: KarrotLocalCountryCode;
};
export function trackSubmitSearchKeyword({
  keyword,
  serviceName,
  countryCode,
}: TrackSubmitSearchKeywordParams) {
  if (keyword.length === 0) {
    return;
  }

  trackEvent({
    eventName: "submit_search_keyword",
    data: {
      keyword,
      service_name: serviceName,
      country_code: countryCode,
    },
  });
}

type TrackClickButtonMoveToAppParams = {
  position: string;
  countryCode: KarrotLocalCountryCode;
  serviceName?: Services;
  status?: string;
};
export function trackClickButtonMoveToApp({
  position,
  serviceName,
  countryCode,
  status,
}: TrackClickButtonMoveToAppParams) {
  trackEvent({
    eventName: "click_button_moveToApp",
    data: {
      move_to_app_button_position: position,
      country_code: countryCode,
      service_name: serviceName,
      item_status: status,
    },
  });
}
