import type { Values } from "@karrotmarket-com/type-utility";

import type { Services } from "~/core/services";
import { useRootLoaderData } from "~/remix-lib/useRootLoaderData";
import { trackClickButtonMoveToApp as trackClickMoveToAppButtonEvent } from "~/utils/trackEvent";

import useDetectUserAgent from "./useDetectUserAgent";
import type { Position } from "./useMoveToApp";

type TrackClickButtonMoveToAppEventParams = {
  position: Values<typeof Position>;
  serviceName?: Services;
  status?: string;
};

export const useTrackClickButtonMoveToApp = () => {
  const userAgent = useDetectUserAgent();
  const rootData = useRootLoaderData();

  const trackClickButtonMoveToApp = (
    params: TrackClickButtonMoveToAppEventParams,
  ) => {
    if (!userAgent.isKarrotBrowser) {
      trackClickMoveToAppButtonEvent({
        position: params.position,
        countryCode: rootData.karrotLocalCountryCode,
        serviceName: params.serviceName,
        status: params.status,
      });
    }
  };

  return {
    trackClickButtonMoveToApp,
  };
};
