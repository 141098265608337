import useDetectUserAgent from "~/hooks/useDetectUserAgent";

export function useKRAppScheme() {
  const userAgent = useDetectUserAgent();

  const openApp = (appScheme: string) => {
    /**
     * Karrot Browser의 경우 원링크를 사용하지 않고, 앱스킴을 사용합니다.
     * @see https://daangn.slack.com/archives/C04U09CTAH0/p1730730935887689
     */
    if (userAgent.isKarrotBrowser) {
      window.location.href = appScheme;
      return;
    }

    const link = getKRDeferredDeeplink({ deeplink: appScheme });
    window.location.href = link;
  };

  const getQrLink = (appScheme: string) => {
    return getKRDeferredDeeplink({ deeplink: appScheme });
  };

  return { openApp, getQrLink };
}

interface GetKRDeferredDeepLinkOptions {
  deeplink: string;
}

/**
 * 유입소스 별 성과 측정이 필요하지 않고 딥링킹 목적/외부 공유용을 목적으로 하는 원링크 long link를 생성합니다.
 * @see https://support.appsflyer.com/hc/ko/articles/360017132597-%EC%9B%90%EB%A7%81%ED%81%AC-%EA%B8%B4-URL
 * @see https://daangn.slack.com/archives/C02NAL2M5TM/p1734535196022309?thread_ts=1734519153.802229&cid=C02NAL2M5TM
 */
function getKRDeferredDeeplink({ deeplink }: GetKRDeferredDeepLinkOptions) {
  if (deeplink.startsWith("karrot.alpha")) {
    return deeplink;
  }
  const searchParams = new URLSearchParams();

  searchParams.set("af_dp", deeplink);
  searchParams.set("deep_link_value", deeplink);
  /**
   * iOS에서 원링크가 더 잘 작동할 수 있도록 강제 딥링킹을 사용해요
   * @see https://www.notion.so/daangn/Appsflyer-Onelink-v4_-6add7710fbc3470c9d177bdff40654e9?pvs=4#13f28c3a9f8f809db8f0e6e8944bb0aa
   */
  searchParams.set("af_force_deeplink", "true");

  return `https://daangn.onelink.me/oWdR?${searchParams.toString()}`;
}
