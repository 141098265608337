import type {
  PersistentStorage,
  Region,
} from "@karrotmarket-com/persistent-storage";
import type { HTMLAttributes, ReactElement } from "react";
import { forwardRef } from "react";

import clsx from "clsx";
import LocationSelectModal from "~/figma-components/LocationSelectModal";
import { HorizontalKeywords } from "./HorizontalKeywords";
import * as css from "./SearchBar.css";
import { SearchBarLayout } from "./SearchBarLayout";
import SelectRegionButton from "./SelectRegionButton";

export interface SearchBarProps extends HTMLAttributes<HTMLDivElement> {
  region: { id: string; name: string };
  keywordType: "popular" | "related";
  keywordComponentType?: "chip" | "text";
  keywords: { value: string; href: string }[];
  searchForm: ReactElement;
  size?: "medium" | "large";

  onSaveRegionToStorage: PersistentStorage<Region>["set"];
}

export const SearchBar = forwardRef<HTMLDivElement, SearchBarProps>(
  (
    {
      region,
      keywordType,
      keywords,
      className,
      searchForm,
      keywordComponentType,
      size = "medium",
      onSaveRegionToStorage,
      ...props
    },
    ref,
  ) => {
    const showPopularKeywords = keywords.length > 0;

    return (
      <SearchBarLayout.Root
        className={clsx(
          className,
          css.container({
            showPopularKeywords,
          }),
        )}
        ref={ref}
        {...props}
      >
        <SearchBarLayout.Left>
          <LocationSelectModal
            trigger={
              <SelectRegionButton size={size} currentRegionName={region.name} />
            }
            onSaveRegionToStorage={onSaveRegionToStorage}
          />
        </SearchBarLayout.Left>
        <SearchBarLayout.Right>
          {searchForm}
          {showPopularKeywords ? (
            <HorizontalKeywords
              keywordType={keywordType}
              keywords={keywords}
              keywordComponentType={keywordComponentType}
            />
          ) : null}
        </SearchBarLayout.Right>
      </SearchBarLayout.Root>
    );
  },
);

SearchBar.displayName = "SearchBar";
