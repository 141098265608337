import Polyglot from "node-polyglot";
import type { KarrotLang } from "shared/parseKarrotLang";

import en_CA from "./__generated__/en_CA.json";
import en_GB from "./__generated__/en_GB.json";
import en_US from "./__generated__/en_US.json";
import ja_JP from "./__generated__/ja_JP.json";
import ko_KR from "./__generated__/ko_KR.json";

export function getLocale(lang: KarrotLang) {
  const locales = {
    "en-CA": en_CA,
    "en-US": en_US,
    "en-GB": en_GB,
    "ja-JP": ja_JP,
    "ko-KR": ko_KR,
  };

  const polyglot = new Polyglot();
  polyglot.extend(locales[lang]);

  return {
    t(key: keyof typeof en_CA, data?: {}) {
      return polyglot.t(key, {
        ...data,
      });
    },
  };
}
