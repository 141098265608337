import {
  IconMagnifyingglassFill,
  IconXmarkCircleFill,
} from "@daangn/react-monochrome-icon";
import React, { useState } from "react";

import * as css from "./SearchInput.css";

type SearchInputProps = {
  name?: string;
  initialValue?: string | null;
  placeholder?: string;
  border?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange?: (text: string) => void;
};
const SearchInput: React.FC<SearchInputProps> = ({
  border = true,
  autoFocus,
  ...props
}) => {
  const [text, setText] = useState(props.initialValue ?? "");

  return (
    <div className={css.searchInputContainer}>
      <input
        className={css.searchInput({ border })}
        type="search"
        aria-label="Search input"
        placeholder={props.placeholder}
        name={props.name}
        value={text}
        disabled={props.disabled}
        onChange={(e) => {
          setText(e.target.value);
          props.onChange?.(e.target.value);
        }}
        autoFocus={autoFocus}
      />
      {text !== "" && (
        <button
          className={css.searchResetButton}
          type="button"
          disabled={props.disabled}
          aria-label="Reset search results"
          onClick={() => {
            setText("");
            props.onChange?.("");
          }}
        >
          <IconXmarkCircleFill
            className={css.searchResetIcon({
              disabled: props.disabled,
            })}
          />
        </button>
      )}
      <button
        type="submit"
        aria-label="Search"
        disabled={props.disabled}
        className={css.searchInputButton}
      >
        <IconMagnifyingglassFill
          className={css.searchInputIcon({
            disabled: props.disabled,
          })}
        />
      </button>
    </div>
  );
};

export default SearchInput;
