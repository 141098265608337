import { createCanonicalize } from "@karrotmarket-com/core";
import { isFunction, last } from "lodash";

import { canParse } from "~/utils/canParse";

import { buildRegionParamValue } from "./buildRegionParamValue";
import { CommonSearchParamKey } from "./CommonSearchParamKey";

export function normalizeHref(
  href: string,
  options: {
    origin: string;
    region: {
      id: string;
      name?: string;
    };
  },
): string {
  const isAbsoluteUrl = isFunction(URL.canParse)
    ? URL.canParse(href)
    : canParse(href);
  if (isAbsoluteUrl) {
    return href;
  }

  const isRelativeUrl =
    href === "" ||
    href.startsWith("/") ||
    href.startsWith("./") ||
    href.startsWith("../");

  const isExternalHrefMissingProtocol = !isRelativeUrl;
  if (isExternalHrefMissingProtocol) {
    return `http://${href}`;
  }

  const hrefUrl = new URL(href, options.origin);

  const hrefRegionIdParam = hrefUrl.searchParams.get(CommonSearchParamKey.In);
  const hrefRegionId = hrefRegionIdParam && last(hrefRegionIdParam.split("-"));

  const canonicalize = createCanonicalize("alpha");
  const { canonicalUrl } = canonicalize({
    url: hrefUrl.toString(),
  });

  const nextUrl = new URL(canonicalUrl);

  if (hrefRegionId === options.region.id) {
    nextUrl.searchParams.set(
      CommonSearchParamKey.In,
      buildRegionParamValue({
        region: options.region,
      }),
    );
  }

  if (!hrefRegionId) {
    nextUrl.searchParams.set(
      CommonSearchParamKey.In,
      buildRegionParamValue({
        region: options.region,
      }),
    );
  }

  nextUrl.searchParams.sort();
  return nextUrl.pathname + nextUrl.search;
}
