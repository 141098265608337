import {
  Box,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Flex,
  Heading,
  Text,
} from "@daangn/carotene";
// @ts-ignore
import { QRCode } from "react-qr-code";

import { useLocale } from "../remix-lib";
import * as css from "./QrModal.css";

type GlobalQrModalProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  qrLink: string;
  title?: string;
};

export default function GlobalQrModal({
  title,
  open,
  qrLink,
  onOpenChange,
}: GlobalQrModalProps) {
  const { t } = useLocale();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        position={{
          base: "bottom",
          small: "center",
        }}
      >
        <DialogHeader className={css.dialogHeader}>
          <DialogTitle>{title ?? t("QrModal.modal_title")}</DialogTitle>
        </DialogHeader>
        <Box padding={5} paddingTop={12} paddingBottom={16}>
          <Flex
            flexDirection="column"
            alignItems="center"
            gap={8}
            textAlign="center"
            paddingBottom={4}
          >
            <Heading level={2} size="xlarge">
              {t("QrModal.title")}
            </Heading>
            <div className={css.qrCodeBorder}>
              <QRCode value={qrLink} size={210} />
            </div>
            <Text
              size="medium"
              color="neutralMuted"
              className={css.qrCodeDescription}
            >
              {t("QrModal.description")}
            </Text>
          </Flex>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

type KRQrModalProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  qrLink: string;
  title?: string;
};

export const KRQrModal = ({
  title = "QR 코드 스캔",
  open,
  qrLink,
  onOpenChange,
}: KRQrModalProps) => {
  const { t } = useLocale();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        position={{
          base: "bottom",
          medium: "center",
        }}
      >
        <DialogHeader className={css.dialogHeader}>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <Box padding={5}>
          <Flex
            flexDirection="column"
            alignItems="center"
            gap={8}
            paddingTop={12}
            paddingBottom={16}
            textAlign="center"
          >
            <Heading level={2} size="xlarge">
              {t("QrModal.title")}
            </Heading>
            <div className={css.qrCodeBorder}>
              <QRCode value={qrLink} size={210} />
            </div>
            <Text
              size="medium"
              color="neutralMuted"
              className={css.qrCodeDescription}
            >
              {t("QrModal.description")}
            </Text>
          </Flex>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
