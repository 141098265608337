import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Flex,
  ProgressCircle,
  Text,
} from "@daangn/carotene";
import { atoms } from "@daangn/carotene/css";
import { IconCrosshairLine } from "@daangn/react-monochrome-icon";
import { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import type {
  PersistentStorage,
  Region,
} from "@karrotmarket-com/persistent-storage";
import { useLocation } from "@remix-run/react";
import type { Location } from "services";
import { P, match } from "ts-pattern";

import { CommonSearchParamKey, buildRegionParamValue } from "~/core";
import { useDisclosure } from "~/hooks/useDisclosure";
import { Link, useLocale, useRootLoaderData } from "~/remix-lib";
import { useLocationSelectContext } from "~/services/gnb/LocationSelectContext";

import * as css from "./LocationSelectModal.css";
import SearchInput from "./SearchInput";

type LocationSelectModalProps = {
  trigger: React.ReactNode;
  onSaveRegionToStorage: PersistentStorage<Region>["set"];
};

const countryCodeMap = {
  [KarrotLocalCountryCode.CA]: "Canada",
  [KarrotLocalCountryCode.US]: "United States",
  [KarrotLocalCountryCode.JP]: "日本",
  [KarrotLocalCountryCode.KR]: "대한민국",
  [KarrotLocalCountryCode.UK]: "United Kingdom",
};

const LocationSelectModal: React.FC<LocationSelectModalProps> = ({
  trigger,
  onSaveRegionToStorage,
}) => {
  const {
    karrotLocalCountryCode,
    __locationSelectModal: { fallbackLocations },
  } = useRootLoaderData();
  const { t } = useLocale();
  const { isOpen, onToggle, setOpenState } = useDisclosure();
  const {
    loading,
    error,
    locationInput,
    allCountriesLocations,
    onChangeLocationInput,
    searchCurrentPosition,
    resetLocations,
    resetError,
  } = useLocationSelectContext();

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      resetLocations();
      resetError();
    }
    setOpenState(open);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild onClick={onToggle}>
        {trigger}
      </DialogTrigger>
      <DialogContent
        position={{
          base: "bottom",
          small: "center",
        }}
        className={css.modalContent}
      >
        <DialogHeader className={css.dialogHeader}>
          <DialogTitle>{t("LocationSelectModal.title")}</DialogTitle>
        </DialogHeader>
        <div className={css.locationSelectContainer}>
          <div className={css.locationInputContainer}>
            <div className={css.searchInputContainer}>
              <div className={css.searchInputWrapper}>
                <form action="." onSubmit={(e) => e.preventDefault()}>
                  <SearchInput
                    placeholder={t(
                      "LocationSelectModal.search_input_placeholder",
                    )}
                    disabled={loading}
                    onChange={onChangeLocationInput}
                  />
                </form>
              </div>
            </div>
            <div className={css.locationSelectButtonContainer}>
              <Button
                size="medium"
                variant="brandWeak"
                className={atoms({
                  width: "full",
                })}
                disabled={loading}
                onClick={searchCurrentPosition}
              >
                {loading ? (
                  <ProgressCircle size={16} tone="neutral" />
                ) : (
                  <Flex alignItems="center" gap={1}>
                    <IconCrosshairLine size={16} />
                    <Text
                      size="small"
                      weight="strong"
                      color="brand"
                      className={atoms({
                        fontWeight: "bold",
                      })}
                    >
                      {t("LocationSelectModal.gps_button_label")}
                    </Text>
                  </Flex>
                )}
              </Button>
            </div>
          </div>
          <div className={css.recommendationRegionContainer}>
            {match({ allCountriesLocations, error })
              .with(
                {
                  error: P.when(
                    (err) =>
                      err?.type === "geolocation" &&
                      err.error.code ===
                        GeolocationPositionError.PERMISSION_DENIED,
                  ),
                },
                () => (
                  <div className={css.noResultWrapper}>
                    <div className={css.noResultTitle}>
                      {t("LocationSelectModal.geolocation_permission_message")}
                    </div>
                  </div>
                ),
              )
              .with(
                {
                  error: P.not(P.nullish),
                },
                () => (
                  <div className={css.noResultWrapper}>
                    <div className={css.noResultTitle}>
                      {t("LocationSelectModal.search_location_error_message")}
                    </div>
                  </div>
                ),
              )
              .with(
                {
                  allCountriesLocations: P.when((l) => l.length === 0),
                  error: P.nullish,
                },
                () => (
                  <div className={css.noResultWrapper}>
                    <div className={css.noResultTitle}>
                      {t("LocationSelectModal.no_result_title", {
                        query: locationInput,
                      })}
                    </div>
                    <p className={css.noResultDescription}>
                      {t("LocationSelectModal.no_result_description")}
                    </p>
                  </div>
                ),
              )
              .otherwise(() => null)}

            {(locationInput.length === 0 ||
              allCountriesLocations.length === 0) && (
              <div className={css.recommendationRegionHeader}>
                <span className={css.recommendationRegionHeaderText}>
                  {t("LocationSelectModal.recommended_regions_title")}
                </span>
              </div>
            )}

            <section className={css.recommendationRegionContent}>
              {allCountriesLocations.length === 0 ? (
                <LocationComponent
                  locations={fallbackLocations}
                  onToggle={onToggle}
                  karrotLocalCountryCode={karrotLocalCountryCode}
                  onSaveRegionToStorage={onSaveRegionToStorage}
                />
              ) : (
                allCountriesLocations.map((locations) => (
                  <LocationComponent
                    key={locations.karrotLocalCountryCode}
                    locations={locations.locations}
                    onToggle={onToggle}
                    karrotLocalCountryCode={locations.karrotLocalCountryCode}
                    onSaveRegionToStorage={onSaveRegionToStorage}
                  />
                ))
              )}
            </section>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

type LocationProps = {
  karrotLocalCountryCode: KarrotLocalCountryCode;
  locations: Omit<Location, "name1Id" | "name2Id" | "name3Id">[];
  onToggle: () => void;
  onSaveRegionToStorage: PersistentStorage<Region>["set"];
};
const LocationComponent: React.FC<LocationProps> = ({
  karrotLocalCountryCode,
  locations,
  onToggle,
  onSaveRegionToStorage,
}) => {
  const location = useLocation();
  const rootData = useRootLoaderData();
  const currentKarrotLocalCountryCode = rootData.karrotLocalCountryCode;

  return (
    <>
      {currentKarrotLocalCountryCode !== KarrotLocalCountryCode.KR && (
        <span className={css.karrotLocalCountryCodeTitle}>
          {countryCodeMap[karrotLocalCountryCode]}
        </span>
      )}
      <ul>
        {locations.map((loc) => {
          const searchParams = new URLSearchParams(location.search);

          searchParams.set(
            CommonSearchParamKey.In,
            buildRegionParamValue({
              region: {
                id: String(loc.id),
                name: loc.name,
              },
            }),
          );

          const pathname =
            karrotLocalCountryCode === currentKarrotLocalCountryCode
              ? location.pathname
              : `/${karrotLocalCountryCode}/`;

          return (
            <li key={loc.id} className={css.recommendationRegionListItem}>
              <Link
                className={css.recommendationRegionListItemAnchor}
                onClick={() => {
                  if (karrotLocalCountryCode === rootData.countryCodeByIp) {
                    onSaveRegionToStorage({
                      regionId: loc.id.toString(),
                      regionName: loc.name,
                      countryCode: karrotLocalCountryCode,
                    });
                  }

                  onToggle();
                }}
                href={`${pathname}?${searchParams.toString()}`}
              >
                {match({
                  depth: loc.depth,
                  karrotLocalCountryCode,
                })
                  .with(
                    {
                      depth: 3,
                      karrotLocalCountryCode: KarrotLocalCountryCode.KR,
                    },
                    () => {
                      if (loc.name1 === "세종특별자치시") {
                        return `${loc.name1}, ${loc.name}`;
                      }
                      return `${loc.name1}, ${loc.name2}, ${loc.name}`;
                    },
                  )
                  .with(
                    {
                      depth: 2,
                      karrotLocalCountryCode: KarrotLocalCountryCode.KR,
                    },
                    () => `${loc.name1}, ${loc.name}`,
                  )
                  .with(
                    {
                      depth: 3,
                    },
                    () => `${loc.name}, ${loc.name2}`,
                  )
                  .otherwise(() => loc.name)}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default LocationSelectModal;
