import type { KarrotLocalCountryCode } from "@karrotmarket-com/core";
import { createContext, useContext } from "react";

import type { LocationError } from "~/figma-components/useLocationSelectModal";

// TODO: services 폴더 하위로 LocationSelectContext, useLocationSelectModel 옮기고 타입 추출해서 정리하기
type Location = {
  id: number;
  name: string;
  name1: string;
  name2: string;
  name3: string;
  depth: number;
};

export interface LocationSelectContextValue {
  loading: boolean;
  error: LocationError;
  locationInput: string;
  allCountriesLocations: {
    karrotLocalCountryCode: KarrotLocalCountryCode;
    locations: Location[];
  }[];
  onChangeLocationInput: (value: string) => void;
  searchCurrentPosition: () => void;
  resetLocations: () => void;
  resetError: () => void;
}

const defaultContext = {
  loading: false,
  error: null,
  locationInput: "",
  allCountriesLocations: [],
  onChangeLocationInput: () => {},
  searchCurrentPosition: () => {},
  resetLocations: () => {},
  resetError: () => {},
};

export const LocationSelectContext =
  createContext<LocationSelectContextValue>(defaultContext);

export const useLocationSelectContext = (): LocationSelectContextValue => {
  const context = useContext<LocationSelectContextValue>(LocationSelectContext);
  if (context === undefined) {
    throw new Error(
      "useLocationSelectContext must be used within `LocationSelectContextProvider`",
    );
  }
  return context;
};
