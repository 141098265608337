import clsx from "clsx";
import { type HTMLAttributes, forwardRef } from "react";
import * as css from "./SearchBarLayout.css";

const SearchBarLayoutRoot = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} {...props} className={clsx(className, css.container)}>
      {children}
    </div>
  );
});

const SearchBarLayoutLeft = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} {...props} className={clsx(className, css.searchAreaLeft)}>
      {children}
    </div>
  );
});

const SearchBarLayoutRight = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} {...props} className={clsx(className, css.searchAreaRight)}>
      {children}
    </div>
  );
});

SearchBarLayoutRoot.displayName = "SearchBarLayoutRoot";
SearchBarLayoutLeft.displayName = "SearchBarLayoutLeft";
SearchBarLayoutRight.displayName = "SearchBarLayoutRight";

export const SearchBarLayout = {
  Root: SearchBarLayoutRoot,
  Left: SearchBarLayoutLeft,
  Right: SearchBarLayoutRight,
};
