import { Icon, VisuallyHidden } from "@daangn/carotene";
import { IconMagnifyingglassLine } from "@daangn/react-monochrome-icon";
import clsx from "clsx";
import type { HTMLMotionProps } from "framer-motion";
import { motion } from "framer-motion";
import { forwardRef } from "react";

import * as css from "./SearchButton.css";

interface Props extends HTMLMotionProps<"button"> {
  visible: boolean;
  label: string;
}
export const SearchButton = forwardRef<HTMLButtonElement, Props>(
  ({ visible, label, className, ...props }: Props, ref) => {
    return (
      <motion.button
        {...props}
        initial={false}
        animate={{ opacity: visible ? 1 : 0 }}
        transition={{
          duration: 0.3,
          delay: 0.1,
          ease: "easeInOut",
        }}
        className={clsx(css.searchButton, className)}
        data-show={visible}
        ref={ref}
      >
        <VisuallyHidden>{label}</VisuallyHidden>
        <Icon
          svg={<IconMagnifyingglassLine className={css.block} aria-hidden />}
          size={6}
          color="neutral"
        />
      </motion.button>
    );
  },
);

SearchButton.displayName = "SearchButton";
