import { useSyncExternalStore } from "react";

/**
 * @see https://github.com/sergiodxa/remix-utils/blob/main/src/react/use-hydrated.ts
 * @see https://react.dev/reference/react/useSyncExternalStore
 *
 * @description
 * 마지막 callback은 server에서 렌더링할 때와, client에서 hydration할 때 호출됩니다.
 * 그렇기 때문에, hydration이 끝나면 true를 반환하게 됩니다.
 */
export const useHydrated = () => {
  return useSyncExternalStore(
    () => {
      return () => {};
    },
    () => true,
    () => false,
  );
};
