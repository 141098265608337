import React from "react";

import * as css from "./PageMaxWidthWrapper.css";

type PageMaxWidthWrapperProps = {
  className?: string;
  children: React.ReactNode;
};
const PageMaxWidthWrapper: React.FC<PageMaxWidthWrapperProps> = (props) => {
  return (
    <div
      className={[css.container, props.className].filter((e) => !!e).join(" ")}
    >
      {props.children}
    </div>
  );
};

export default PageMaxWidthWrapper;
